.wrapper {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 33.1% 33.1% 33.1%;
    grid-template-rows: 144px;
    max-width: 1376px;
    width: 100%;
    gap: 4px;
}

.outer {
    display: flex;
    max-width: 1376px;
}

.item-wrapper {
    position: relative;
    display: flex;
    padding: 16px 10px 16px 16px;
    border-radius: 8px;
    box-sizing: border-box;
    gap: 16px;
    flex: 1;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    font-family: "Ali_ver.18_Lighter-Rounded", Inter, sans-serif;
    font-size: 38px;
    line-height: 94%;
    margin-bottom: 10px;
    max-width: 266px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: pre-line;
}

.subtitle {
    font-size: 13px;
    line-height: 16px;
    opacity: 0.7;
    white-space: pre-line;
}

.image-wrapper {
    display: flex;
    flex: 1;
    justify-content: end;
}

.image {
    height: 100%;
}

@media (max-width: 1280px) {
    .wrapper {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

    .item-wrapper {
        gap: 8px;
    }

    .image {
        height: 70px;
    }

    .title {
        font-size: 32px;
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .wrapper {
        max-width: 1216px;
        grid-template-rows: 1fr;
    }

    .item-wrapper {
        padding: 10px 12px;
    }

    .title {
        font-size: 27px;
    }

    .image {
        padding: 0;
        height: 70px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-rows: unset;
        gap: 4px;
        width: 100%;
    }

    .item-wrapper {
        justify-content: space-between;
        height: 100%;
    }

    .image {
        height: 70px;
    }
}

@media (max-width: 398px) {
    .item-wrapper {
        justify-content: space-between;
        height: 100%;
        max-height: unset;
    }

    .image {
        height: 70px;
        margin: auto;
    }
}
